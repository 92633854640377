import React, { Component } from 'react';
import './Contact.scss';

class Contact extends Component {


    render() {
        return (
            <div className="bigWhiteWrapper contact-container" >
                <div>
                    <h1>Contact</h1><br />
                    <p>The datasets are provided “as-is”. Equinor will not provide any support or further explanation to the content of the data files. If you experience technical issues related to access or download of the datasets, please <a href="/faq" rel="noopener noreferrer"> visit the data portal FAQ</a>, <a rel="noopener noreferrer" href="/forum">consult the data portal forum</a> or <a target="_blank" rel="noopener noreferrer" href="https://www.equinor.com/en/about-us/contact-us.html">
                            follow this link to notify Equinor.
                    </a>
                    </p>
                </div><br />
            </div>
        );
    }
}

export default Contact;