import Button from '@mui/material/Button';
import { fetchingUser, login, logout } from 'actions';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    useEffect(() => {
        rest.fetchingUser();
    }, [rest]);

    return (
        <Route
            {...rest}
            render={(props) =>
                rest.isLoggedIn ? (
                    <Component {...props} />
                ) : (
                    <>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                rest.login('regular');
                            }}
                        >
                            Equinor Employee
                        </Button>
                        <div>&nbsp;</div>
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => {
                                rest.login('B2C');
                            }}
                        >
                            Log in B2C
                        </Button>
                    </>
                )
            }
        />
    );
};

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.userData.isLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
        login: (accessProviderType) => dispatch(login(accessProviderType)),
        fetchingUser: () => dispatch(fetchingUser()),
    };
};

PrivateRoute.propTypes = {
    // Properties
    isLoggedIn: PropTypes.bool,

    // Functions
    login: PropTypes.func,
    logout: PropTypes.func,
    fetchingUser: PropTypes.func,
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(PrivateRoute)
);
