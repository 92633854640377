import { put } from "redux-saga/effects";
import { actionTypes } from "common/actionTypes";
import {
  login,
  logout,
  getUserProfile,
  loginB2C,
  getUserAccount,
} from "services";
import { api } from "services";

export function* loginUser(action) {
  try {
    const accessProviderType = action.payload;

    if (accessProviderType === "B2C") {
      yield loginB2C();
    } else {
      yield login();
    }

    yield put({
      type: actionTypes.user.LOGIN_USER_SUCCESS_OR_ERROR,
      payload: {
        isLoggedIn: true,
        user: null,
        hasError: false,
        errorMsg: "",
      },
    });
    yield put({
      type: actionTypes.user.FETCHING_USER,
      payload: null,
    });
  } catch (error) {
    if (error.message.substring(0, 11) === "AADB2C90118") {
      yield put({
        type: actionTypes.user.LOGIN_USER_SUCCESS_OR_ERROR,
        payload: {
          isLoggedIn: false,
          user: null,
          hasError: true,
          errorMsg: "AADB2C90118",
        },
      });
    } else {
      yield put({
        type: actionTypes.user.LOGIN_USER_SUCCESS_OR_ERROR,
        payload: {
          isLoggedIn: false,
          user: null,
          hasError: true,
          errorMsg: error.message,
        },
      });
    }
  }
}

export function* getUser() {
  try {
    const loggedInUser = yield getUserAccount();

    if (loggedInUser == null) return;

    const user = yield getUserProfile();

    const payload = {
      status: true,
      user: user,
      hasError: false,
      errorMsg: "",
    };

    yield put({
      type: actionTypes.user.FETCHING_USER_SUCCESS_OR_ERROR,
      payload: payload,
    });
  } catch (error) {
    yield put({
      type: actionTypes.user.FETCHING_USER_SUCCESS_OR_ERROR,
      payload: {
        status: false,
        user: null,
        hasError: true,
        errorMsg: error.message,
      },
    });
  }
}

export function* logoutUser() {
  try {
    yield logout();
    yield put({
      type: actionTypes.user.LOGOUT_USER_SUCCESS_OR_ERROR,
      payload: {
        hasError: false,
        errorMsg: "",
      },
    });
  } catch (error) {
    yield put({
      type: actionTypes.user.LOGOUT_USER_SUCCESS_OR_ERROR,
      payload: {
        hasError: true,
        errorMsg: error.message,
      },
    });
  }
}

export function* getUserData() {
  try {
    const userData = yield api.default.getUserData();

    yield put({
      type: actionTypes.user.ROLE_TYPE_SUCCESS_OR_ERROR,
      payload: { userData: userData },
    });
  } catch (error) {
    yield put({
      type: actionTypes.user.ROLE_TYPE_SUCCESS_OR_ERROR,
      payload: { userData: null },
    });
  }
}
