import React from 'react';

export function formatDate(date) {
    return (
        <span>{new Intl.DateTimeFormat('en-GB', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit'
        }).format(Date.parse(date))}</span>
    )
}

export function formatTime(date) {
    return (
        <span>{new Intl.DateTimeFormat('en-GB', {
            hour: 'numeric',
            minute: 'numeric',            
            hour12: false
        }).format(Date.parse(date))}</span>
    )
}

export function createMarkup(markup) {
    return { __html: markup }
}

export function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
};

export function getNextId(entries) {
    if (entries == null || entries.length < 0)
        return -1;

    var ids = [];
    entries.forEach(element => {
        if(element.id < 0)
            ids.push(element.id);

        element.children.forEach(child => {
            if(child.id < 0)
                ids.push(child.id);
        });
    });
    ids = ids.sort((a, b) => a - b);
    
    if(ids.length <= 0)
        return -1;

    return (ids[0] - 1);
}

export function getLinkId(entries) {
    if (entries == null || entries.length < 0)
        return -1;

    var ids = [];
    entries.forEach(element => {
        if(element.id < 0)
            ids.push(element.id);        
    });
    ids = ids.sort((a, b) => a - b);
    
    if(ids.length <= 0)
        return -1;

    return (ids[0] - 1);
}