import { actionTypes } from 'common/actionTypes';

const initialState = {
    fetching: false,
    entries: null,
    forumCategories: [],
    hasError: false,
    errorMsg: ''
}

export default function forum(state = initialState, action) {

    switch (action.type) {

        // Forum categories
        case actionTypes.forum.FORUM_FETCH_ALL_CATEGORIES_SUCCESS_OR_ERROR:
            return { ...state, forumCategories: action.payload.forumCategories, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        // Forum
        case actionTypes.forum.FORUM_FETCH_ALL:
            return { 
                ...state, 
                fetching: true, 
                hasError: false, 
                errorMsg: '' };

        case actionTypes.forum.FORUM_FETCH_ALL_SUCCESS_OR_ERROR:
            return { 
                ...state, 
                fetching: false, 
                entries: action.payload.entries, 
                hasError: action.payload.hasError, 
                errorMsg: action.payload.errorMsg 
            };

        case actionTypes.forum.FORUM_SET_EDIT_MODE:
            return {
                ...state,
                entries: [
                    ...state.entries.map((item) => item.id === action.payload.id ? {
                        ...item,
                        isEditMode: action.payload.value
                    } : item)
                ]
            };

        case actionTypes.forum.FORUM_SET_CHILD_EDIT_MODE:
            return {
                ...state,
                entries: [
                    ...state.entries.map((item, i) => item.id === action.payload.parentId ? {
                        ...item,
                        children: [
                            ...state.entries[i].children.map((child) => child.id === action.payload.id ? { ...child, isEditMode: action.payload.value } : child)
                        ]
                    } : item)
                ]
            };

        case actionTypes.forum.FORUM_CREATE_CHILD:
            return {
                ...state,
                entries: [
                    ...state.entries.map((item, i) => item.id === action.payload.parentId ? {
                        ...item,
                        children: state.entries[i].children.concat(action.payload)
                    } : item)
                ]
            };

        case actionTypes.forum.FORUM_CREATE:
            return {
                ...state,
                entries: [
                    ...[action.payload].concat(state.entries)
                ],
            };
        case actionTypes.forum.FORUM_DELETE_CHILD:
            return { 
                ...state, 
                fetching: true, 
                hasError: false, 
                errorMsg: '' 
            };

        case actionTypes.forum.FORUM_DELETE:
            return { 
                ...state, 
                fetching: true, 
                hasError: false, 
                errorMsg: '' 
            };

        case actionTypes.forum.FORUM_DELETE_CHILD_SUCCESS_OR_ERROR: {
            if (action.payload.hasError) {
                return { 
                    ...state, 
                    fetching: false, 
                    hasError: action.payload.hasError, 
                    errorMsg: action.payload.errorMsg 
                };
            } else {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg,
                    entries: [
                        ...state.entries.map((item, i) => item.id === action.payload.parentId ? {
                            ...item,
                            children: state.entries[i].children.filter(child => child.id !== action.payload.id)
                        } : item)
                    ]
                };
            }
        }

        case actionTypes.forum.FORUM_DELETE_SUCCESS_OR_ERROR: {
            if (action.payload.hasError) {
                return { 
                    ...state, 
                    fetching: false, 
                    hasError: action.payload.hasError, 
                    errorMsg: action.payload.errorMsg 
                };
            } else {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg,
                    entries: [
                        ...state.entries.filter(entry => entry.id !== action.payload.id)
                    ]
                };
            }
        }

        case actionTypes.forum.FORUM_CHILD_CHANGED:
            return {
                ...state,
                entries: [
                    ...state.entries.map((item, i) => item.id === action.payload.parentId ? {
                        ...item, 
                        children: [
                            ...state.entries[i].children.map((child) => child.id === action.payload.id ? { 
                                ...child, 
                                content: action.payload.message 
                            } : child)
                        ]
                    } : item)
                ]
            };

        case actionTypes.forum.FORUM_CHANGED:
            return {
                ...state,
                entries: [
                    ...state.entries.map((item) => item.id === action.payload.id ? {
                        ...item,
                        title: action.payload.title,
                        content: action.payload.message,
                        category: action.payload.category
                    } : item)
                ]
            };

        case actionTypes.forum.FORUM_SAVE:
            return { 
                ...state, 
                fetching: true, 
                hasError: false, 
                errorMsg: '' 
            };

        case actionTypes.forum.FORUM_SAVE_SUCCESS_OR_ERROR: {
            if (action.payload.hasError) {
                return { 
                    ...state, 
                    fetching: false, 
                    hasError: action.payload.hasError, 
                    errorMsg: action.payload.errorMsg 
                };
            } else {
                if (action.payload.entry.parentId != null) {
                    return {
                        ...state,
                        fetching: false,
                        hasError: action.payload.hasError,
                        errorMsg: action.payload.errorMsg,
                        entries: [
                            ...state.entries.map((item, i) => item.id === action.payload.entry.parentId ? {
                                ...item, children: [
                                    ...state.entries[i].children.map((child) => child.id === action.payload.oldId ? {
                                        ...child,
                                        id: action.payload.entry.id,
                                        updated: action.payload.entry.updated,
                                        created: action.payload.entry.created,
                                        userName: action.payload.entry.userName,
                                        editButtonVisible: action.payload.entry.editButtonVisible,
                                        deleteButtonVisible: action.payload.entry.deleteButtonVisible,
                                        author: action.payload.entry.author,
                                        isEditMode: false
                                    } : child)
                                ]
                            } : item)
                        ]
                    };
                } else {
                    return {
                        ...state,
                        fetching: false,
                        hasError: action.payload.hasError,
                        errorMsg: action.payload.errorMsg,
                        entries: [
                            ...state.entries.map((item) => item.id === action.payload.oldId ? {
                                ...item,
                                id: action.payload.entry.id,
                                updated: action.payload.entry.updated,
                                created: action.payload.entry.created,
                                userName: action.payload.entry.userName,
                                editButtonVisible: action.payload.entry.editButtonVisible,
                                deleteButtonVisible: action.payload.entry.deleteButtonVisible,
                                author: action.payload.entry.author,
                                isEditMode: false
                            } : item)
                        ]
                    };
                }
            }
        }
        
        default:
            return state;
    }
}
