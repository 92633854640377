import { actionTypes } from 'common/actionTypes';

const initialState = {
    fetching: false,
    faqs: [],
    hasError: false,
    errorMsg: ''
}

export default function commonReducer(state = initialState, action) {
    switch (action.type) {
        // Load the list
        case actionTypes.common.FETCH_FAQ:
            return { ...state, fetching: true, hasError: false, errorMsg: '' };

        case actionTypes.common.FETCH_FAQ_SUCCESS_OR_ERROR:
            return { ...state, fetching: false, faqs: action.payload.faqs, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        case actionTypes.global.RESET_ERROR:
            return { ...state, hasError: false, errorMsg: '' }

        default:
            return state;
    }
}