import { Divider } from '@equinor/eds-core-react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import './DataStrategy.scss';

const DataStrategy = () => {
    return (
        <div className='bigWhiteWrapper'>
            <div className='data-strategy-intro'>
                <h1>Equinor Data Strategy v1.0</h1>
                <p>Enterprise Data Management - May 2019</p>
                <Divider />
                <p>
                    Data Strategy describes a “set of choices and decisions that
                    together, chart a high-level course of action to achieve
                    high-level goals.” <br />
                    This includes business plans to use information to a
                    competitive advantage and support enterprise goals
                </p>
            </div>
            <Divider />
            <h2>Purpose of the Data Strategy</h2>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <DataStrategyItem text='Support delivery of the digital vision and the business priorities by unlocking the value of relevant data' />
                    <DataStrategyItem text='Define governance and data management principles for actively treating data as a strategic asset' />
                    <DataStrategyItem text='Accelerate a culture shift towards data-driven insight and decision' />
                </Grid>
            </Box>
            <Divider />
            <h2>Strategic Beliefs</h2>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <DataStrategyItem
                        index={1}
                        imageName='compass'
                        text='The ability to enhance value creation by leveraging data assets across the company will be a competitive differentiator and external competition will intensify.'
                    />
                    <DataStrategyItem
                        index={2}
                        imageName='expand'
                        text='A good data foundation is needed for scaling digitalization initiatives at pace.'
                    />
                    <DataStrategyItem
                        index={3}
                        imageName='growth'
                        text='Increased external collaboration across digital ecosystems will be essential for generating new opportunities, efficiency, and insights.'
                    />
                    <DataStrategyItem
                        index={4}
                        imageName='graph'
                        text='Volumes and types of data will continue to grow and the focus on information security will intensify.'
                    />
                    <DataStrategyItem
                        index={5}
                        imageName='culture-shift'
                        text='Unlocking the value of data will require a culture shift and stronger digital and data capabilities.'
                    />
                </Grid>
            </Box>
            <Divider />
            <h2>Data Principles</h2>
            <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                    <DataStrategyItem
                        index={1}
                        imageName='people'
                        title='Data is used'
                        text='Incentivise the active use of data and insights to accelerate a culture shift across disciplines, functions, and business areas.'
                    />
                    <DataStrategyItem
                        index={2}
                        imageName='asset'
                        title='Data is an asset'
                        text='Data is an asset that has value to the company and is managed to ensure quality throughout its lifecycle.'
                    />
                    <DataStrategyItem
                        index={3}
                        imageName='safeguard'
                        title='Data is safeguarded'
                        text='All data that is classified as sensitive or personal is protected from unauthorized use or disclosure.'
                    />
                    <DataStrategyItem
                        index={4}
                        imageName='acquire'
                        title='Data is acquired'
                        text='Internal and external data central to Equinor’s operations is acquired in a timely manner, in a machine-readable format, with known rights to use, and suitable quality.'
                    />
                    <DataStrategyItem
                        index={5}
                        imageName='vr-headset'
                        title='Data is available'
                        text='Equinor data is made easily available internally across the business anytime, anywhere, according to rights to use.'
                    />
                    <DataStrategyItem
                        index={6}
                        imageName='shared'
                        title='Selected data is shared externally'
                        text='Equinor data is shared externally, either with selected parties in the ecosystem or openly, whenever it provides value to Equinor in line with the business priorities and according to competitive, commercial and legal restrictions.'
                    />
                </Grid>
            </Box>
        </div>
    );
};

const DataStrategyItem = ({ index, imageName, title, text }) => {
    return (
        <Grid item md={4}>
            {imageName && (
                <div className='data-strategy-img-container'>
                    <img
                        className='data-strategy-img'
                        src={`/images/data-strategy/${imageName}.png`}
                        alt={title}
                    />
                </div>
            )}
            {index && <span className='data-strategy-span'>{index}</span>}
            {title && <h3>{title}</h3>}
            {text && <p>{text}</p>}
        </Grid>
    );
};

export default DataStrategy;
