import React from 'react';
import './Dataset.scss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dataset_fetch } from './../../actions';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatDate, createMarkup } from './../../common/renders';
import DatasetLink from './DatasetLink';
import Disclaimer from './../Common/Disclaimer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';

class Dataset extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            displayDescription: false
        }

        this.showMoreClicked = this.showMoreClicked.bind(this);
    }

    componentDidMount() {
        const key = this.props.match.params.name;
        this.props.dataset_fetch(key);
    }

    showMoreClicked() {
        this.setState(state => ({ displayDescription: !state.displayDescription }));
    }

    render() {
        return (
            <React.Fragment>
                {this.props.dataset &&
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className='title-and-icon'>
                                        <Link to="/"><FontAwesomeIcon className='font-awesome-icon' icon={faArrowAltCircleLeft} /></Link>
                                        <h1>{this.props.dataset.title}</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <Row>
                                        <Col>
                                            <div className='bigWhiteWrapperWithoutShadow'>
                                                <table className='datasetDescTable'>
                                                    <tbody>
                                                        <tr>
                                                            <th><b>Last update:</b></th>
                                                            <td>{formatDate(this.props.dataset.updated)}</td>
                                                        </tr>
                                                        <tr>
                                                            <th><b>Topic:</b></th>
                                                            <td>{this.props.dataset.topic}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className='bigWhiteWrapperWithoutShadow' style={{ marginTop: '5px'}}>
                                                <div dangerouslySetInnerHTML={createMarkup(this.props.dataset.content)} /><br />
                                                <button className='showMoreButton' onClick={this.showMoreClicked}>{this.state.displayDescription ? 'Less' : 'More'}</button>
                                                <div className='extra-content' style={{ display: this.state.displayDescription ? 'block' : 'none' }}>
                                                    <div dangerouslySetInnerHTML={createMarkup(this.props.dataset.extraContent)} />
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {this.props.dataset.userHasAccepted &&
                                        <DatasetLink dataset={this.props.dataset} />
                                    }

                                    {!this.props.dataset.userHasAccepted &&
                                        <Disclaimer dataset={this.props.dataset} />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        dataset: state.dataSetList.dataset
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dataset_fetch: (key) => dispatch(dataset_fetch(key)),
    };
};

Dataset.propTypes = {
    // Properties
    dataset: PropTypes.object,
    // Functions
    dataset_fetch: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Dataset);
