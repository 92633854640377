import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'react-bootstrap';

// Material ui
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import NativeSelect from '@mui/material/NativeSelect';
import DateFnsUtils from '@date-io/date-fns';

import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Yup
import * as yup from 'yup';

// Local imports
import RichTextField from './RichTextField';
import EditExternalLinks from './EditExternalLinks';
import { dataset_fetch_all, dataset_save, change_publish_status, delete_dataset } from './../../actions/';

import './EditDataset.scss';

const schema = yup.object({
    title: yup.string().required(),
    key: yup.string().required(),
    topic: yup.string().nullable().required(),
    shortIntro: yup.string().required().max(255),
    externalUrl: yup.string().nullable()
        .when('isExternal', {
            is: (isExternal) => isExternal === true,
            then: yup.string().nullable().required()
        })
});

class EditDataset extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedTab: 0,
            editMode: false,
            obj: {
                id: -1,
                key: '',
                title: '',
                topic: '',
                shortIntro: '',
                content: '',
                extraContent: '',
                officialPublishDate: new Date(),
                license: '',
                isExternal: false,
                externalUrl: '',
                image: '',
                externalDataLinks: [],
                published: 0
            },
            validationError: {},
            files: []
        }

        this.newOrEditBtnClicked = this.newOrEditBtnClicked.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.externalDataLinksChanged = this.externalDataLinksChanged.bind(this);
        this.save = this.save.bind(this);
        this.addValidationError = this.addValidationError.bind(this);
        this.removeValidationError = this.removeValidationError.bind(this);
        this.getFiles = this.getFiles.bind(this);
        this.changePublishStatus = this.changePublishStatus.bind(this);
        this.delete = this.delete.bind(this);
    }

    delete() {
        this.props.delete_dataset(this.state.obj.id);
        this.handleChange('key_select', 'NewDataset');
    }

    changePublishStatus() {
        const newStatus = this.state.obj.published === 1 ? 0 : 1;
        this.props.change_publish_status(this.state.obj.id, newStatus);
        let obj = { ...this.state.obj };
        obj.published = newStatus;
        this.setState({ obj });
    }

    getFiles(files) {
        let obj = { ...this.state.obj };
        obj.image = files[0].split(',')[1];
        this.setState({ files: files });
        this.setState({ obj });
    }

    componentDidMount() {
        this.props.dataset_fetch_all();
    }

    newOrEditBtnClicked(isNew) {
        this.setState(() => ({ editMode: !isNew }));
    }

    addValidationError(error) {
        let validationError = { ...this.state.validationError };
        validationError = {};
        validationError[error.path] = error.message;
        this.setState({ validationError });
    }

    removeValidationError() {
        let validationError = { ...this.state.validationError };
        validationError = {};
        this.setState({ validationError });
    }

    handleChange(id, value) {
        const obj = { ...this.state.obj };

        switch (id) {
            case 'key_select':
                if (value === 'NewDataset') {
                    this.setState(() => ({ editMode: false }));
                    obj.id = -1;
                    obj.officialPublishDate = new Date();
                    obj.title = '';
                    obj.key = '';
                    obj.topic = '';
                    obj.shortIntro = '';
                    obj.isExternal = false;
                    obj.externalUrl = '';
                    obj.externalDataLinks = [];
                    obj.content = '';
                    obj.extraContent = '';
                    obj.license = '';
                    obj.image = '';
                    obj.published = 0;
                } else {
                    this.setState(() => ({ editMode: true }));
                    const currentDataset = this.props.datasets.filter(item => item.key === value)[0];
                    obj.id = currentDataset.id;
                    obj.officialPublishDate = currentDataset.officialPublishDate;
                    obj.title = currentDataset.title;
                    obj.key = currentDataset.key;
                    obj.topic = currentDataset.topic;
                    obj.shortIntro = currentDataset.shortIntro;
                    obj.isExternal = currentDataset.isExternal;
                    obj.externalUrl = currentDataset.externalUrl;
                    obj.externalDataLinks = currentDataset.externalDataLinks;
                    obj.content = currentDataset.content;
                    obj.extraContent = currentDataset.extraContent;
                    obj.license = currentDataset.license;
                    obj.image = currentDataset.image;
                    obj.published = currentDataset.published;
                }

                this.setState({ obj });
                this.setState(() => ({ selectedTab: 0 }));
                break;
            case 'is_external':
                obj.isExternal = value;
                this.setState({ obj });
                break;
            case 'date_input':
                obj.officialPublishDate = new Date(value);
                this.setState({ obj });
                break;
            case 'title_input':
                obj.title = value;
                this.setState({ obj });
                break;
            case 'externalUrl_input':
                obj.externalUrl = value;
                this.setState({ obj });
                break;
            case 'key_input':
                obj.key = value;
                this.setState({ obj });
                break;
            case 'topic_input':
                obj.topic = value;
                this.setState({ obj });
                break;
            case 'introduction_input':
                obj.shortIntro = value;
                this.setState({ obj });
                break;
            case 'tab':
                this.setState(() => ({ selectedTab: value }));
                break;
            case 'content_input':
                obj.content = value;
                this.setState({ obj });
                break;
            case 'extra_content_input':
                obj.extraContent = value;
                this.setState({ obj });
                break;
            case 'license_input':
                obj.license = value;
                this.setState({ obj });
                break;
            default:
                console.log('No matching key: ' + id);
        }
    }

    externalDataLinksChanged(links) {
        let obj = { ...this.state.obj };
        obj.externalDataLinks = links;
        this.setState({ obj: obj });
    }

    save() {

        schema.validate(this.state.obj)
            .then(function (value) {

            }).catch(error => this.addValidationError(error));

        const _this = this;

        schema.isValid(this.state.obj).then(function (valid) {
            if (valid) {
                _this.removeValidationError();
                _this.props.dataset_save(_this.state.obj);
            }
        });
    }

    render() {
        return (
            <React.Fragment>
                {(!this.props.user || (!this.props.user.isAdmin && !this.props.user.isEditor)) &&
                    <React.Fragment>
                        <div></div>
                    </React.Fragment>
                }
                <React.Fragment>
                    {this.props.user && (this.props.user.isAdmin || this.props.user.isEditor) &&
                        <React.Fragment>
                            <Container className='edit-container'>
                                <Row className='row-full-width'>
                                    <Col md={12}>
                                        <div className='btn-container'>
                                            <button className={this.state.editMode ? 'btn -active' : 'btn'} disabled>Edit dataset</button>
                                            <button className={!this.state.editMode ? 'btn -active' : 'btn'} disabled>New dataset</button>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                            <Container className='edit-container'>
                                <Row className='form-row'>
                                    <Col md={4} className='select'>
                                        <FormControl>
                                            <InputLabel shrink htmlFor="key_select">Dataset</InputLabel>
                                            <NativeSelect
                                                value={this.state.obj.key || ''}
                                                onChange={e => this.handleChange('key_select', e.target.value)}
                                                inputProps={{
                                                    name: 'Dataset',
                                                    id: 'key_select',
                                                }}>

                                                <option value="NewDataset">New dataset</option>
                                                {this.props.datasets &&
                                                    <React.Fragment>
                                                        {this.props.datasets.map(dataset => (
                                                            <option key={dataset.key} value={dataset.key}>{dataset.key}</option>
                                                        ))}
                                                    </React.Fragment>
                                                }
                                            </NativeSelect>
                                            <FormHelperText></FormHelperText>
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <FormControlLabel
                                            className='checkbox'
                                            control={<Checkbox checked={this.state.obj.isExternal || false} onChange={() => this.handleChange('is_external', !this.state.obj.isExternal)} name="is_external" />}
                                            label="Is external"
                                        />
                                    </Col>
                                    <Col md={4}>
                                        <LocalizationProvider utils={DateFnsUtils}>
                                            <DatePicker
                                                margin="normal"
                                                id="date_picker"
                                                label="Publish date"
                                                format='yyyy/MM/dd'
                                                value={this.state.obj.officialPublishDate || new Date()}
                                                onChange={e => this.handleChange('date_input', e)}
                                                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                                            />
                                        </LocalizationProvider>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col md={6}>
                                        <FormControl>
                                            <TextField variant="outlined" id="externalUrl_input" label="External url" value={this.state.obj.externalUrl || ''} onChange={e => this.handleChange('externalUrl_input', e.target.value)} disabled={!this.state.obj.isExternal} error={this.state.validationError['externalUrl'] != null} helperText={this.state.validationError['externalUrl'] ?? ''} />
                                        </FormControl>
                                    </Col>
                                    <Col md={2}>

                                    </Col>
                                    <Col md={4}>
                                        <Row>
                                            <Col md={12}>
                                                <img className='preview-image' src={`data:image/png;base64,${this.state.obj.image}`} alt={this.state.obj.title} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12}>
                                                <label className="custom-file-upload">
                                                    <FileUploader
                                                        multiple={false}
                                                        onDone={this.getFiles}
                                                    />
                                Change image
                                </label>
                                            </Col>
                                        </Row>


                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col md={4}>
                                        <FormControl>
                                            <TextField variant="outlined" id="title_input" label="Title" value={this.state.obj.title || ''} onChange={e => this.handleChange('title_input', e.target.value)} error={this.state.validationError['title'] != null} helperText={this.state.validationError['title'] ?? ''} />
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <FormControl>
                                            <TextField variant="outlined" id="key_input" label="Key" value={this.state.obj.key || ''} onChange={e => this.handleChange('key_input', e.target.value)} disabled={this.state.editMode} error={this.state.validationError['key'] != null} helperText={this.state.validationError['key'] ?? ''} />
                                        </FormControl>
                                    </Col>
                                    <Col md={4}>
                                        <FormControl>
                                            <TextField variant="outlined" id="topic_input" label="Topic" value={this.state.obj.topic || ''} onChange={e => this.handleChange('topic_input', e.target.value)} error={this.state.validationError['topic'] != null} helperText={this.state.validationError['topic'] ?? ''} />
                                        </FormControl>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col md={12}>
                                        <TextField
                                            id="introduction_input"
                                            label="Introduction"
                                            multiline
                                            minRows={6}
                                            value={this.state.obj.shortIntro || ''}
                                            onChange={(e) => this.handleChange('introduction_input', e.target.value)}
                                            error={this.state.validationError['shortIntro'] != null}
                                            helperText={this.state.validationError['shortIntro'] ?? ''}
                                            variant="outlined"
                                        />
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col md={12}>
                                        <Tabs
                                            value={this.state.selectedTab || 0}
                                            onChange={(_e, value) => this.handleChange('tab', value)}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            variant="fullWidth"
                                            aria-label=""
                                        >
                                            <Tab label="Content" id='tab-0' />
                                            <Tab label="Extra content" id='tab-1' />
                                            <Tab label="Licence" id='tab-2' />
                                            <Tab label="External links" id='tab-3' />
                                        </Tabs>
                                    </Col>
                                </Row>

                                <Row className='form-row'>
                                    <Col md={12}>
                                        {this.state.selectedTab === 0 &&
                                            <RichTextField fieldValue={this.state.obj.content || ''} onChange={(value) => this.handleChange('content_input', value)} />
                                        }
                                        {this.state.selectedTab === 1 &&
                                            <RichTextField fieldValue={this.state.obj.extraContent || ''} onChange={(value) => this.handleChange('extra_content_input', value)} />
                                        }
                                        {this.state.selectedTab === 2 &&
                                            <RichTextField fieldValue={this.state.obj.license || ''} onChange={(value) => this.handleChange('license_input', value)} />
                                        }
                                        {this.state.selectedTab === 3 && this.state.obj.key.length > 0 &&
                                            <EditExternalLinks onChange={this.externalDataLinksChanged} currentKey={this.state.obj.key} datasetId={this.state.obj.id} externalDataLinks={this.state.obj.externalDataLinks} />
                                        }
                                    </Col>
                                </Row>

                                <Row className='row-full-width'>
                                    <Col md={12}>
                                        <button className='float-right edit-button' onClick={this.save}>Save dataset</button>
                                        {this.state.editMode &&
                                            <React.Fragment>
                                                <button className='float-right edit-button' onClick={this.changePublishStatus}>{this.state.obj.published === 1 ? 'Unpublish dataset' : 'Publish dataset'}</button>
                                                <button className='float-right edit-button edit-button--red' onClick={this.delete}>Delete dataset</button>
                                            </React.Fragment>
                                        }
                                    </Col>
                                </Row>

                            </Container>
                        </React.Fragment>
                    }
                </React.Fragment>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        datasets: state.dataSetList.datasets,
        user: state.userData.data
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dataset_fetch_all: () => dispatch(dataset_fetch_all(2)),
        dataset_save: (dataset) => dispatch(dataset_save(dataset)),
        change_publish_status: (datasetId, status) => dispatch(change_publish_status(datasetId, status)),
        delete_dataset: (datasetId) => dispatch(delete_dataset(datasetId)),
    };
};

EditDataset.propTypes = {
    // Properties
    datasets: PropTypes.array,
    user: PropTypes.object,

    // Functions
    dataset_fetch_all: PropTypes.func,
    dataset_save: PropTypes.func,
    change_publish_status: PropTypes.func,
    delete_dataset: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(EditDataset);


const FileUploader = ({onDone,multiple}) => {

    async function addFiles(e) {
        e.preventDefault();
        const filesToAdd = Array.from(e.target.files);
        const completedFiles = [];
        for(let i = 0; i < filesToAdd.length; i++) {
            const file = filesToAdd[i];
            const base64File = await convertFileTob64(file);
            completedFiles.push(base64File);
        }
        onDone(completedFiles);
    };

    return (
        <input
            type="file"
            onChange={addFiles}
            multiple={multiple}
        />
    )
}

async function convertFileTob64(file) {
    function getBase64(file) {
        return new Promise(function(resolve) {
            const reader = new FileReader();
            reader.onloadend = function() {
            resolve(reader);
            }
            reader.readAsDataURL(file);
        })
    }
    const base64 = await getBase64(file);
    const result = base64.result;
    return result;
}