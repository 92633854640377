import { actionTypes } from "common/actionTypes";

export function fetchingUser() {
  return {
    type: actionTypes.user.FETCHING_USER,
    payload: null,
  };
}

export function fetchedUser(user) {
  return {
    type: actionTypes.user.FETCHING_USER_SUCCESS,
    payload: user,
  };
}

export function login(accessProviderType) {
  return {
    type: actionTypes.user.LOGIN_USER,
    payload: accessProviderType,
  };
}

export function logout() {
  return {
    type: actionTypes.user.LOGOUT_USER,
    payload: null,
  };
}
