import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { withRouter } from "react-router-dom";

import { accept_disclaimer } from './../../actions/common';
import { createMarkup } from './../../common/renders';
import './Disclaimer.scss';

class Disclaimer extends Component {

    constructor(props) {
        super(props);
        this.acceptTerms = this.acceptTerms.bind(this);
    }

    acceptTerms(status) {
        if (status === true) {
            this.props.accept_disclaimer(this.props.dataset.key);
        } else {
            this.props.history.push("/");
        }
    }

    render() {
        return (

            <div className="bigWhiteWrapper" style={{ textAlign: 'left' }}>

                {this.props.dataset?.license &&
                    <div dangerouslySetInnerHTML={createMarkup(this.props.dataset.license)} />                    
                }

                <br />
                <button style={{ marginRight: '10px ' }} className="disclaimer-btn" onClick={() => this.acceptTerms(false)}>Go back</button>
                <button className="disclaimer-btn" onClick={() => this.acceptTerms(true)}>Submit</button>
            </div>

        );
    }
}


const mapStateToProps = state => {
    return {
        dataset: state.dataSetList.dataset
    };
}

const mapDispatchToProps = dispatch => {
    return {
        accept_disclaimer: (key) => dispatch(accept_disclaimer(key)),
    };
};

Disclaimer.propTypes = {
    // Properties
    dataset: PropTypes.object,
    // Functions
    accept_disclaimer: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Disclaimer));
