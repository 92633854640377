import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

class Admin extends Component {

    render() {
        return (
            <React.Fragment>
                {this.props.user && this.props.user.isAdmin &&
                    <React.Fragment>
                        <Container className='admin-container'>
                            <Row className='row-full-width'>
                                <Col md={12}>
                                    <p>This module missing implementation</p>
                                </Col>
                            </Row>
                        </Container>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        user: state.userData.data
    };
}

const mapDispatchToProps = dispatch => {
    return {
        
    };
};

Admin.propTypes = {
    // Properties
    user: PropTypes.object,

    // Functions
    
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
