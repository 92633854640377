import {authProvider} from 'services';

/**
 * fetchJson - Calling api on given url. The method and body used, is properties method and body.
 * @method
 * @param  {String} url The url to the request. If baseUrl - baseUrl + url
 * @param  {} params object containing the params
 * @param  init The url to the request. If baseUrl - baseUrl + url
 * @return {Promise} Returns the resolved promise
 */
function fetchJson(endpoint, params, init, body){   
    return authProvider.getAccessToken().then(
        (accessToken) => {
            const stringfiedAccessToken = accessToken.accessToken;
            init = {...init, 
                withCredentials: true,  
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${stringfiedAccessToken}`
                }                
            }

            if(body != null && Object.keys(body).length > 0)
                init.body = JSON.stringify(body)
        
            const finalUrl = new URL(API_BASE_URL + API_VERSION + endpoint);
            const queryParams = Object.keys(params);
            if (queryParams.length > 0) {
              queryParams.forEach((key) => {
                if (params[key]) {
                  finalUrl.searchParams.append(key, params[key]);
                }
              });
            }
            
            return fetch(finalUrl, init)
                .then(response => {
                    return new Promise((resolve, reject) => {
                        if ((response.status >= 200 && response.status < 300) || response.status === 400) {
                            var contentType = response.headers.get('content-type');
                            if (contentType && contentType.indexOf('application/json') !== -1) {
                                response.json().then(json => {
                                    resolve(json);
                                });
                            } else {
                                resolve({});
                            }
                        } else {
                            reject(response);
                        }
                    });
                })
                .catch((error) => {
                    if (!error.status) {
                        throw new Error("Internal Server Error", 500);
                    } else {
                        throw new Error(error.statusText, error.status);
                    }
                });


        }
    );
    
    
}

const API_VERSION = '';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const getInit = {
    method: "GET",
}

const postInit = {
    method: "POST", 
}

const putInit = {
    method: "PUT", 
}

const deleteInit = {
    method: "DELETE", 
}


export default {
    getUserData: () => {return fetchJson(`Editor/user`, {}, getInit)},
    getDataset: (publishStatus) => {return fetchJson(`Dataset/`, {'publishStatus': publishStatus}, getInit)},
    getDatasetDetails: (key) => {return fetchJson(`Dataset/Details/${key}`, {}, getInit)},
    acceptDisclaimer: (key) => {return fetchJson(`Dataset/AcceptDisclaimer/${key}`, {}, postInit)},
    getFaqs: () => {return fetchJson(`Misc/faq`, {}, getInit)},
    getForums: (categoryId, number) => {return fetchJson(`Forum/`, {'id': categoryId, 'number': number}, getInit)},
    updateForum: (id, model) => {return fetchJson(`Forum/${id}`, {}, putInit, model)},
    createForum: (model) => {return fetchJson(`Forum/`, {}, postInit, model)},
    deleteForum: (id) => {return fetchJson(`Forum/${id}`, {}, deleteInit, null)},
    getForumCategories: () => {return fetchJson(`Forum/Categories`, {}, getInit)},  
    createDataset: (dataset) => {return fetchJson(`Editor`, {}, postInit, dataset)},  
    updateDataset: (id, dataset) => {return fetchJson(`Editor/${id}`, {}, putInit, dataset)},
    changePublishStatus: (datasetId, status) => {return fetchJson(`Editor/publish/${datasetId}`, {publishStatus:status}, postInit)},
    deleteDataset: (datasetId) => {return fetchJson(`Editor/${datasetId}`, {}, deleteInit)}
}