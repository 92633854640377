import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { dataset_fetch_all } from 'actions/dataset';
import DatasetList from './../Dataset/DatasetList';
import './Home.scss';

class Home extends Component {
    componentDidMount() {
        this.props.dataset_fetch_all();
    }

    render() {
        return (
            <React.Fragment>
                {this.props.datasets &&
                    <React.Fragment>
                        <h3>Find open data</h3>
                        <DatasetList datasetList={this.props.datasets} />
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        datasets: state.dataSetList.datasets,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        dataset_fetch_all: () => dispatch(dataset_fetch_all(1)),
    };
};

Home.propTypes = {
    // Properties
    datasets: PropTypes.array,
    // Functions
    dataset_fetch_all: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
