import { actionTypes } from 'common/actionTypes';
import { AuthenticationActions, AuthenticationState } from 'react-aad-msal';

const initialState = {
    isLoggedIn: false,
    user: null,
    fetching: false,
    idToken: null,
    accessToken: null,
    state: AuthenticationState.Unauthenticated,
    hasError: false,
    errorMsg: '',
    data: null
};

export default function userData(state = initialState, action) {
    switch (action.type) {
        // Login, logout user
        case actionTypes.user.LOGIN_USER_SUCCESS_OR_ERROR:
            return { ...state, fetching: false, isLoggedIn: action.payload.status, user: action.payload.user, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        case actionTypes.user.LOGOUT_USER_SUCCESS_OR_ERROR:
            return { ...state, fetching: false, isLoggedIn: false, user: null, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        // Fetching user
        case actionTypes.user.FETCHING_USER:
            return { ...state, fetching: true, hasError: false, errorMsg: '' };

        case actionTypes.user.FETCHING_USER_SUCCESS_OR_ERROR:
            return { ...state, fetching: false, isLoggedIn: action.payload.status, user: action.payload.user, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        case actionTypes.user.ROLE_TYPE_SUCCESS_OR_ERROR:
            return { ...state, data: action.payload.userData };

        // Global
        case actionTypes.global.RESET_ERROR:
            return { ...state, hasError: false, errorMsg: '' }

        case AuthenticationActions.fetching:
            console.log('AuthenticationActions.fetching');
            return {
                ...state,
                fetching: true,
                isLoggedIn: false,
                hasError: false,
                errorMsg: ''
            };

        case AuthenticationActions.isLoggedIn:
            console.log('AuthenticationActions.isLoggedIn');
            return {
                ...state,
                fetching: false,
                isLoggedIn: true,
                hasError: false,
                errorMsg: ''
            };

        case AuthenticationActions.AcquiredIdTokenSuccess:
            console.log('AuthenticationActions.AcquiredIdTokenSuccess');
            return {
                ...state,
                idToken: action.payload,
                hasError: false,
                errorMsg: ''
            };

        case AuthenticationActions.AcquiredAccessTokenSuccess:
            console.log('AuthenticationActions.AcquiredAccessTokenSuccess');
            return {
                ...state,
                accessToken: action.payload,
                hasError: false,
                errorMsg: ''
            };

        case AuthenticationActions.AcquiredAccessTokenError:
            console.log('AcquiredAccessTokenError: ' + action.payload);
            return {
                ...state,
                accessToken: null,
            };

        case AuthenticationActions.LoginSuccess:
            console.log('AuthenticationActions.LoginSuccess');
            return {
                ...state,
                account: action.payload.account,
                hasError: false,
                errorMsg: ''
            };
        case AuthenticationActions.LoginError:
        case AuthenticationActions.AcquiredIdTokenError:
            return { ...state, idToken: null, accessToken: null, account: null };
        case AuthenticationActions.LogoutSuccess:
            return { ...state, idToken: null, accessToken: null, account: null, hasError: false, errorMsg: '' };
        case AuthenticationActions.AuthenticatedStateChanged:
            return {
                ...state,
                state: action.payload,
                hasError: false,
                errorMsg: ''
            };
        default:
            return state;
    }
}
