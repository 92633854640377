import { put } from 'redux-saga/effects';
import { actionTypes } from 'common/actionTypes';
import { api } from 'services';

export function* acceptDisclaimer(action) {
    try {
        yield api.default.acceptDisclaimer(action.payload.key);
        yield put({
            type: actionTypes.common.ACCEPT_DISCLAIMER_SUCCESS_OR_ERROR,
            payload: {
                hasError: false,
                errMsg: '',
                status: true
            }
        });
    } catch(error) {
        yield put({
            type: actionTypes.common.ACCEPT_DISCLAIMER_SUCCESS_OR_ERROR,
            payload: {
                hasError: true,
                errMsg: error.message,
                status: false
            }
        });
    }
}

export function* getFaqs() {
    try {
        const result = yield api.default.getFaqs();

        yield put({
            type: actionTypes.common.FETCH_FAQ_SUCCESS_OR_ERROR,
            payload: {
                hasError: false,
                errorMsg: '',
                faqs: result
            }
        });
    } catch(error) {
        yield put({
            type: actionTypes.common.FETCH_FAQ_SUCCESS_OR_ERROR,
            payload: {
                hasError: true,
                errorMsg: error.message,
                faqs: []
            }
        });
    }
}