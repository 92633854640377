import { put } from 'redux-saga/effects';
import { actionTypes } from 'common/actionTypes';
import { api } from 'services';

export function* getDatasetAPI(action) {
    try {
        const result = yield api.default.getDataset(action.payload.publishStatus);
        yield put({
            type: actionTypes.dataset.FETCH_ALL_SUCCESS_OR_ERROR,
            payload: {
                datasets: result,
                hasError: false,
                errorMsg: ''
            }
        });
    } catch (error) {
        yield put({
            type: actionTypes.dataset.FETCH_ALL_SUCCESS_OR_ERROR,
            payload: {
                datasets: null,
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}

export function* getDatasetDetailsAPI(action) {

    try {
        const result = yield api.default.getDatasetDetails(action.payload.key);
        yield put({
            type: actionTypes.dataset.FETCH_DATASET_SUCCESS_OR_ERROR,
            payload: {
                dataset: result,
                hasError: false,
                errorMsg: ''
            }
        });
    } catch (error) {
        yield put({
            type: actionTypes.dataset.FETCH_DATASET_SUCCESS_OR_ERROR,
            payload: {
                dataset: null,
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}

export function* saveDataset(action) {
    try {
        var dataset = action.payload.dataset;
        let result = null;

        const model = {
            key: dataset.key,
            title: dataset.title,
            topic: dataset.topic,
            shortIntro: dataset.shortIntro,
            content: dataset.content,
            extraContent: dataset.extraContent,
            officialPublishDate: dataset.officialPublishDate,
            License: dataset.license,
            isExternal: dataset.isExternal,
            externalUrl: dataset.externalUrl,
            image: dataset.image,
            externalDataLinks: dataset.externalDataLinks
        };

        if(dataset.id <= 0) {
             result = yield api.default.createDataset(model);            
        } else {
            result = yield api.default.updateDataset(dataset.id, model);
        }

        yield put({
            type: actionTypes.dataset.SAVE_DATASET_SUCCESS_OR_ERROR,
            payload: {
                dataset: result,
                hasError: false,
                errorMsg: ''
            }
        });

    } catch(error) {
        yield put({
            type: actionTypes.dataset.SAVE_DATASET_SUCCESS_OR_ERROR,
            payload: {
                dataset: null,
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}

export function* changePublishStatus(action) {
    try {
        yield api.default.changePublishStatus(action.payload.datasetId, action.payload.status);

        yield put({
            type: actionTypes.dataset.CHANGE_PUBLISH_STATUS_SUCCESS_OR_ERROR,
            payload: {
                datasetId: action.payload.datasetId,
                status: action.payload.status,
                hasError: false,
                errorMsg: ''
            }
        });

    } catch(error) {
        yield put({
            type: actionTypes.dataset.CHANGE_PUBLISH_STATUS_SUCCESS_OR_ERROR,
            payload: {
                datasetId: null,
                status: null,
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}

export function* deleteDataset(action) {
    try {
        yield api.default.deleteDataset(action.payload.datasetId);

        yield put({
            type: actionTypes.dataset.DELETE_DATASET_SUCCESS_OR_ERROR,
            payload: {
                datasetId: action.payload.datasetId,
                hasError: false,
                errorMsg: ''
            }
        });

    } catch(error) {
        yield put({
            type: actionTypes.dataset.DELETE_DATASET_SUCCESS_OR_ERROR,
            payload: {
                datasetId: null,
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}
