import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { createMarkup } from './../../common/renders';
import './Faq.scss';
import { fetch_faq } from './../../actions/common';

class Faq extends Component {

    componentDidMount() {
        this.props.fetch_faq();
    }


    render() {
        return (
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='title-and-icon'>
                            <Link to="/"><FontAwesomeIcon className='font-awesome-icon' icon={faArrowAltCircleLeft} /></Link>
                            <h1>Frequently Asked Questions</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="bigWhiteWrapper">
                        {this.props.faqs &&
                            <React.Fragment>
                                {this.props.faqs.map(faq => (
                                    <div key={faq.id}>
                                        <h3 className="question" dangerouslySetInnerHTML={createMarkup(faq.question)} />
                                        <p className="answer" dangerouslySetInnerHTML={createMarkup(faq.answer)} />
                                    </div>))}

                            </React.Fragment>
                        }
                    </Col>
                </Row>
            </Container>
        );
    }
}


const mapStateToProps = state => {
    return {
        faqs: state.common.faqs
    };
}

const mapDispatchToProps = dispatch => {
    return {
        fetch_faq: () => dispatch(fetch_faq()),
    };
};

Faq.propTypes = {
    // Functions
    fetch_faq: PropTypes.func,
    //Properties
    faqs: PropTypes.array,
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
