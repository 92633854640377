import {
    Admin,
    Contact,
    Footer,
    Forum,
    Header,
    Home,
    Layout,
} from 'components';
import DataStrategy from 'components/Common/DataStrategy';
import PrivateRoute from 'components/PrivateRoute/PrivateRoute';
import PropTypes from 'prop-types';
import { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Faq from './../Common/Faq';
import Spinner from './../Common/Spinner';
import Dataset from './../Dataset/Dataset';
import EditDataset from './../Editor/EditDataset';
import './App.scss';

class App extends Component {
    constructor(props) {
        super(props);
        this.visibleClassName = this.visibleClassName.bind(this);
    }

    visibleClassName(visible) {
        if (
            this.props.fetchingDataSetList ||
            this.props.fetchingCommon ||
            this.props.fetchingForum
        )
            return visible
                ? 'row-full-width disabled'
                : 'row-full-width no-display';
        else return 'row-full-width';
    }

    render() {
        return (
            <Container>
                {(this.props.fetchingDataSetList ||
                    this.props.fetchingCommon ||
                    this.props.fetchingForum) && <Spinner />}
                <Row className={this.visibleClassName(true)}>
                    <Header />
                </Row>
                <Row className={this.visibleClassName(false)}>
                    <Layout>
                        <Switch>
                            <PrivateRoute exact path='/' component={Home} />
                            <PrivateRoute
                                path='/dataset/:name'
                                component={Dataset}
                            />
                            <PrivateRoute
                                path='/editor'
                                component={EditDataset}
                            />
                            <PrivateRoute path='/faq' component={Faq} />
                            <PrivateRoute path='/forum' component={Forum} />
                            <PrivateRoute path='/admin' component={Admin} />
                            <PrivateRoute path='/contact' component={Contact} />
                            <Route
                                path='/data-strategy'
                                component={DataStrategy}
                            />
                        </Switch>
                    </Layout>
                </Row>
                <Row className={this.visibleClassName(true)}>
                    <Footer />
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.userData.isLoggedIn,
        fetchingDataSetList: state.dataSetList.fetching,
        fetchingCommon: state.common.fetching,
        fetchingForum: state.forum.fetching,
    };
};

App.propTypes = {
    // Properties
    isLoggedIn: PropTypes.bool,
    fetchingDataSetList: PropTypes.bool,
    fetchingCommon: PropTypes.bool,
    fetchingForum: PropTypes.bool,

    // Functions
    login: PropTypes.func,
    logout: PropTypes.func,
    fetchingUser: PropTypes.func,
};

export default connect(mapStateToProps, null)(App);
