import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { formatDate } from './../../common/renders';
import './DatasetListItem.scss';

class DatasetListItem extends React.Component {
    render() {
        const dataset = this.props.dataset;
        let url = 'dataset/' + dataset.key;
        if (dataset.isExternal && dataset.externalUrl) {
            url = dataset.externalUrl;
        }
        return (
            <React.Fragment>
                <a href={url}>
                    <li className='dataset-item'>
                        <Container>
                            <Row>
                                <Col className='dataset-image-container' md={3}>
                                    {dataset.image &&
                                        <img className='dataset-image' src={`data:image/png;base64,${dataset.image}`} alt={dataset.title} />
                                    }
                                </Col>
                                <Col className='dataset-content' md={9}>
                                    <p className="title">{dataset.title}</p>
                                    <p>{formatDate(dataset.officialPublishDate)}</p>
                                    <p>{dataset.shortIntro}</p>
                                </Col>
                            </Row>
                        </Container>





                    </li>
                </a>
                <li className='dataset-item-external-link'>
                    <p className='external-data-links'>
                        {dataset.externalDataLinks.map(link =>
                            <a key={link.id} className='external-data-link' href={link.url}>{link.title}</a>
                        )}
                    </p>
                </li>
            </React.Fragment>
        );
    }
}

export default DatasetListItem;
