import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Contactless, Policy, Assignment } from '@mui/icons-material';

import { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer extends Component {
    render() {
        return (
            <footer className='fixed-bottom g-0'>
                <BottomNavigation showLabels>
                    <BottomNavigationAction
                        label='Private policy'
                        icon={<Policy />}
                        rel='noopener noreferrer'
                        href='https://www.equinor.com/en/about-us/site-info/privacy-policy.html'
                        target='_blank'
                    />
                    <BottomNavigationAction
                        label='Contact'
                        icon={<Contactless />}
                        to='/contact'
                        component={Link}
                    />
                    <BottomNavigationAction
                        label='Data strategy'
                        icon={<Assignment />}
                        to='/data-strategy'
                        component={Link}
                    />
                </BottomNavigation>
            </footer>
        );
    }
}

export default Footer;
