import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-bootstrap';
import Error from './../Common/Error';
import './Layout.scss';

class Layout extends Component {
    render() {
        return (
            <Fragment>
                <Row className='row-full-width'>
                    <Col>
                        <div className='mainContent'>
                            <Error />
                            {this.props.children}
                        </div>
                    </Col>
                </Row>
                <Row className='row-full-width bottom-row'></Row>
            </Fragment>
        );
    }
}

export default Layout;
