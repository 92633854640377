import { combineReducers, createStore, compose, applyMiddleware } from 'redux';
import userData from 'reducers/user';
import dataSetList from 'reducers/dataset';
import commonReducer from 'reducers/common';
import forum from './forum';
import thunk from 'redux-thunk';
import { sagas } from 'sagas';
import createSagaMiddleware from 'redux-saga';


const reducers = combineReducers({
    userData,
    dataSetList,
    common: commonReducer,
    forum: forum
});

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

// dev tools middleware
let reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
  reduxDevTools = a => a;
}
// create a redux store with our reducer above and middleware
const store = createStore(
  reducers,
  compose(applyMiddleware(thunk, sagaMiddleware))
);

sagaMiddleware.run(sagas);

export default store;