import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { reset_error } from "./../../actions/global";
import "./Error.scss";

class Error extends Component {
  constructor(props) {
    super(props);
    this.hasError = this.hasError.bind(this);
    this.errorRedirect = this.errorRedirect.bind(this);
  }

  hasError() {
    return (
      this.props.userData.hasError ||
      this.props.dataSetList.hasError ||
      this.props.common.hasError
    );
  }

  isPasswordReset() {
    return (
      this.props.userData.hasError &&
      this.props.userData.errorMsg === "AADB2C90118"
    );
  }

  errorRedirect() {
    this.props.reset_error();
    this.props.history.push("/");
  }

  renderPasswordReset() {
    return (
      <React.Fragment>
        {this.isPasswordReset() && (
          <div className="bigWhiteWrapper" style={{ textAlign: "center" }}>
            <div>
              <h1>Password reset Successfully!</h1>
            </div>
            <br />
            <button
              className="redirect-btn"
              onClick={() => this.errorRedirect()}
            >
              Go back
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }

  renderError() {
    return (
      <React.Fragment>
        {this.hasError() && !this.isPasswordReset() && (
          <div className="bigWhiteWrapper" style={{ textAlign: "center" }}>
            <div>
              <h1>Ooops something went wrong.</h1>
              <br />
              <p>Unfortunately, we were unable to perform the task.</p>
              <br />
              <b>Error message:</b>
              {this.props.userData && this.props.userData.errorMsg && (
                <p>{this.props.userData.errorMsg}</p>
              )}
              {this.props.dataSetList && this.props.dataSetList.errorMsg && (
                <p>{this.props.dataSetList.errorMsg}</p>
              )}
              {this.props.common && this.props.common.errorMsg && (
                <p>{this.props.common.errorMsg}</p>
              )}
            </div>
            <br />
            <button
              className="redirect-btn"
              onClick={() => this.errorRedirect()}
            >
              Go back
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }

  render() {
    return (
      <div>
        {this.renderError()}
        {this.renderPasswordReset()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
    dataSetList: state.dataSetList,
    common: state.common,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reset_error: () => dispatch(reset_error()),
  };
};

Error.propTypes = {
  // Properties
  userData: PropTypes.object,
  dataSetList: PropTypes.object,
  common: PropTypes.object,

  // Functions
  reset_error: PropTypes.func,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Error));
