import { all, takeEvery } from 'redux-saga/effects';
import { actionTypes } from 'common/actionTypes';
import { getUser, loginUser, logoutUser, getUserData } from './user';
import { getDatasetAPI, getDatasetDetailsAPI, saveDataset, changePublishStatus, deleteDataset } from './datasets';
import { acceptDisclaimer, getFaqs } from './common';
import { getForumEntries, saveForumEntry, deleteForumEntry, getForumCategories } from './forum';

export function* sagas() {
    yield all ([
        takeEvery(actionTypes.user.FETCHING_USER, getUser),       
        takeEvery(actionTypes.user.FETCHING_USER, getUserData),       
        takeEvery(actionTypes.user.LOGIN_USER, loginUser),
        takeEvery(actionTypes.user.LOGOUT_USER, logoutUser),
        takeEvery(actionTypes.dataset.FETCH_ALL, getDatasetAPI),
        takeEvery(actionTypes.dataset.FETCH_DATASET, getDatasetDetailsAPI),
        takeEvery(actionTypes.dataset.SAVE_DATASET, saveDataset),
        takeEvery(actionTypes.dataset.CHANGE_PUBLISH_STATUS, changePublishStatus),
        takeEvery(actionTypes.dataset.DELETE_DATASET, deleteDataset),
        takeEvery(actionTypes.common.ACCEPT_DISCLAIMER, acceptDisclaimer),
        takeEvery(actionTypes.common.FETCH_FAQ, getFaqs),
        takeEvery(actionTypes.forum.FORUM_FETCH_ALL, getForumEntries),
        takeEvery(actionTypes.forum.FORUM_SAVE, saveForumEntry),
        takeEvery(actionTypes.forum.FORUM_DELETE_CHILD, deleteForumEntry),
        takeEvery(actionTypes.forum.FORUM_DELETE, deleteForumEntry),
        takeEvery(actionTypes.forum.FORUM_FETCH_ALL_CATEGORIES, getForumCategories)        
    ]);
}