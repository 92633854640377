import React, { Component } from 'react';
import loadingLogo from './../../assets/images/loading.gif';
import './Spinner.scss';

class Spinner extends Component {

    render() {
        return (
            <div className='spinner'><img src={loadingLogo} alt="loading..." /></div>
        );
    }
}

export default Spinner;
