import { actionTypes } from 'common/actionTypes';

export function forum_fetch_all_categories() {
    return {
        type: actionTypes.forum.FORUM_FETCH_ALL_CATEGORIES, 
        payload: null
    };
}

export function forum_fetch_all(categoryId, number) {
    return {
        type: actionTypes.forum.FORUM_FETCH_ALL, 
        payload: {
            categoryId: categoryId,
            number: number
        }
    };
}

export function forum_set_edit_mode(id, value) {
    return {
        type: actionTypes.forum.FORUM_SET_EDIT_MODE, 
        payload: {
            id: id,
            value: value
        }
    };
}

export function forum_set_child_edit_mode(parentId, id, value) {
    return {
        type: actionTypes.forum.FORUM_SET_CHILD_EDIT_MODE, 
        payload: {
            parentId: parentId,
            id: id,
            value: value
        }
    };
}

export function forum_create_child(entry) {
    return {
        type: actionTypes.forum.FORUM_CREATE_CHILD, 
        payload: entry
    };
}

export function forum_create(entry) {
    return {
        type: actionTypes.forum.FORUM_CREATE, 
        payload: entry
    };
}

export function forum_delete_child(parentId, id) {
    return {
        type: actionTypes.forum.FORUM_DELETE_CHILD, 
        payload: {
            parentId: parentId,
            id: id
        }
    };
}

export function forum_delete(id) {
    return {
        type: actionTypes.forum.FORUM_DELETE, 
        payload: {
            parentId: null,
            id: id
        }
    };
}

export function forum_child_changed(parentId, id, message) {
    return {
        type: actionTypes.forum.FORUM_CHILD_CHANGED, 
        payload: {
            parentId: parentId,
            id: id,
            message: message
        }
    };
}

export function forum_changed(id, title, message, category) {
    return {
        type: actionTypes.forum.FORUM_CHANGED, 
        payload: {
            id: id,
            title: title,
            message: message,
            category: category
        }
    };
}

export function forum_save(entry) {
    return {
        type: actionTypes.forum.FORUM_SAVE, 
        payload: entry
    };
}
