import { actionTypes } from 'common/actionTypes';

export function accept_disclaimer(key) {
    return {
        type: actionTypes.common.ACCEPT_DISCLAIMER, 
        payload: {key:key}
    };
    
}

export function fetch_faq() {
    return {
        type: actionTypes.common.FETCH_FAQ, 
        payload: null
    };
}