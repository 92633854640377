import { put } from 'redux-saga/effects';
import { actionTypes } from 'common/actionTypes';
import { api } from 'services';

export function* getForumCategories() {
    try {
        const result = yield api.default.getForumCategories();
        yield put({
            type: actionTypes.forum.FORUM_FETCH_ALL_CATEGORIES_SUCCESS_OR_ERROR,
            payload: {
                forumCategories: result,
                hasError: false,
                errorMsg: ''
            }
        });
    } catch (error) {
        yield put({
            type: actionTypes.forum.FORUM_FETCH_ALL_CATEGORIES_SUCCESS_OR_ERROR,
            payload: {
                forumCategories: [],
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}

export function* getForumEntries(action) {
    try {

        const result = yield api.default.getForums(action.payload.categoryId, action.payload.number);
        yield put({
            type: actionTypes.forum.FORUM_FETCH_ALL_SUCCESS_OR_ERROR,
            payload: {
                entries: result,
                hasError: false,
                errorMsg: ''
            }
        });
    } catch (error) {
        yield put({
            type: actionTypes.forum.FORUM_FETCH_ALL_SUCCESS_OR_ERROR,
            payload: {
                entries: null,
                hasError: true,
                errorMsg: error.message
            }
        });
    }
}

export function* saveForumEntry(action) {
    try {
        const data = action.payload;
        const model = {
            title: data.title,
            content: data.content,
            categoryId: data.categoryId,
            parentId: data.parentId
        };

        const entry = data.id > 0 ?
            yield api.default.updateForum(data.id, model) :
            yield api.default.createForum(model);

        yield put({
            type: actionTypes.forum.FORUM_SAVE_SUCCESS_OR_ERROR,
            payload: {
                entry: entry,
                hasError: false,
                errorMsg: '',
                oldId: data.id
            }
        });
    } catch (error) {
        yield put({
            type: actionTypes.forum.FORUM_SAVE_SUCCESS_OR_ERROR,
            payload: {
                hasError: true,
                errorMsg: error.message,
                entry: null,
                oldId: null
            }
        });
    }
}

export function* deleteForumEntry(action) {
    try {
        yield api.default.deleteForum(action.payload.id)

        if (action.payload.parentId != null) {
            yield put({
                type: actionTypes.forum.FORUM_DELETE_CHILD_SUCCESS_OR_ERROR,
                payload: {
                    parentId: action.payload.parentId,
                    id: action.payload.id,
                    hasError: false,
                    errorMsg: ''
                }
            });
        } else {
            yield put({
                type: actionTypes.forum.FORUM_DELETE_SUCCESS_OR_ERROR,
                payload: {
                    parentId: null,
                    id: action.payload.id,
                    hasError: false,
                    errorMsg: ''
                }
            });
        }
    } catch (error) {
        if (action.payload.parentId != null) {
            yield put({
                type: actionTypes.forum.FORUM_DELETE_CHILD_SUCCESS_OR_ERROR,
                payload: {
                    parentId: null,
                    id: null,
                    hasError: true,
                    errorMsg: error.message
                }
            });
        } else {
            yield put({
                type: actionTypes.forum.FORUM_DELETE_SUCCESS_OR_ERROR,
                payload: {
                    parentId: null,
                    id: null,
                    hasError: true,
                    errorMsg: error.message
                }
            });
        }
    }
}
