import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentAlt, faPenSquare, faTrash, faSave, faWindowClose, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

// Material-ui
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import ForumChildEntry from './ForumChildEntry';
import { formatDate, formatTime } from './../../common/renders';
import './ForumEntry.scss';

class ForumEntry extends Component {

    constructor(props) {
        super(props);

        this.childChanged = this.childChanged.bind(this);
        this.setChildEditModeClicked = this.setChildEditModeClicked.bind(this);
        this.deleteChildClicked = this.deleteChildClicked.bind(this);
    }

    childChanged(id, value) {
        this.props.childChanged(this.props.entry.id, id, value);
    }

    setChildEditModeClicked(id, value) {
        this.props.setChildEditMode(this.props.entry.id, id, value)
    }

    deleteChildClicked(id) {
        this.props.deleteChild(this.props.entry.id, id);
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md={4}>
                        <span className='margin-right'>{formatDate(this.props.entry.updated)}</span>
                        <span>{formatTime(this.props.entry.updated)}</span>
                    </Col>
                    <Col md={6}>
                        <span className='float-right'>{this.props.entry.author}</span>
                    </Col>
                    <Col md={2} className='header-menu'>
                        {this.props.entry.deleteButtonVisible &&
                            <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faTrash} size='lg' onClick={() => this.props.forumDelete(this.props.entry.id)} />
                        }
                        {!this.props.entry.isEditMode && this.props.entry.editButtonVisible &&
                            <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faPenSquare} size='lg' onClick={() => this.props.setEditMode(this.props.entry.id, !this.props.entry.isEditMode)} />
                        }
                        {this.props.entry.isEditMode &&
                            <React.Fragment>
                                <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faWindowClose} size='lg' onClick={() => this.props.setEditMode(this.props.entry.id, !this.props.entry.isEditMode)} />
                                <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faSave} size='lg' onClick={() => this.props.forumSave(this.props.entry)} />
                            </React.Fragment>
                        }
                    </Col>
                </Row>

                {!this.props.entry.isEditMode &&
                    <React.Fragment>
                        <Row>
                            <Col>
                                <h3 className="forum-header">
                                    <span className='header-icon'>
                                        <FontAwesomeIcon icon={faCommentAlt} />
                                    </span>
                                    {this.props.entry.title}
                                </h3>
                                <div className='entry-content'>
                                    <p>{this.props.entry.content}</p>
                                    <span className='header-menu-icon float-right'>
                                        <FontAwesomeIcon className='icon-color' icon={faPlusSquare} size='lg' onClick={() => this.props.addChild(this.props.entry.id)} />
                                    </span>
                                </div>
                            </Col>
                        </Row>
                    </React.Fragment>
                }

                {this.props.entry.isEditMode &&
                    <Row>
                        <Col>
                            <FormControl>
                                <TextField 
                                    variant="outlined" 
                                    id="title_input" 
                                    label="Title (max 50 characters)" 
                                    value={this.props.entry.title || ''} 
                                    helperText="" 
                                    inputProps={{maxLength:50}}
                                    onChange={e => this.props.forumChanged(this.props.entry.id, e.target.value, this.props.entry.content, this.props.entry.category)} 
                                />
                            </FormControl>

                            <TextField
                                id="message_input"
                                label="Comment (max 255 characters)"
                                multiline
                                minRows={6}
                                value={this.props.entry.content || ''}
                                variant="outlined"
                                inputProps={{maxLength:255}}
                                onChange={e => this.props.forumChanged(this.props.entry.id, this.props.entry.title, e.target.value, this.props.entry.category)}
                            />
                        </Col>
                    </Row>
                }

                {this.props.entry && this.props.entry.children &&
                    <React.Fragment>
                        {this.props.entry.children.map(child => (
                            <div key={child.id}>
                                <ForumChildEntry
                                    entry={child}
                                    setEditMode={this.setChildEditModeClicked}
                                    delete={this.deleteChildClicked}
                                    childChanged={this.childChanged}
                                    forumSave={this.props.forumSave}
                                />
                            </div>))}
                    </React.Fragment>
                }
            </Container>

        );
    }
}

ForumEntry.propTypes = {
    // Properties
    entry: PropTypes.object,
    // Functions
    setEditMode: PropTypes.func.isRequired,
    setChildEditMode: PropTypes.func.isRequired,
    addChild: PropTypes.func.isRequired,
    deleteChild: PropTypes.func.isRequired,
    childChanged: PropTypes.func.isRequired,
    forumChanged: PropTypes.func.isRequired,
    forumSave: PropTypes.func.isRequired,
    forumDelete: PropTypes.func.isRequired
};

export default ForumEntry;
