import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Bootstrap
import { Row, Col } from 'react-bootstrap';
// Material ui
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
// Font awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { getLinkId } from './../../common/renders';
import './EditExternalLinks.scss';

class EditExternalLinks extends Component {

    constructor(props) {
        super(props);

        this.state = {
            key: '',
            externalDataLinks: [],
            datasetId: -1
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
    }

    componentDidMount() {
        this.setState(() => ({ key: this.props.currentKey, externalDataLinks: this.props.externalDataLinks ?? [], datasetId: this.props.datasetId }));
    }

    handleChange(field, index, id, title, url) {
        let links = [...this.state.externalDataLinks];
        let link = { ...links[index] };

        if (field === 'title') {
            link.title = title;
            links[index] = link;
            this.setState({ externalDataLinks: links });
        }

        if (field === 'url') {
            link.url = url;
            links[index] = link;
            this.setState({ externalDataLinks: links });
        }

        this.props.onChange(links);
    }

    handleDelete(id) {
        const newList = this.state.externalDataLinks.filter(item => item.id !== id);
        this.setState({externalDataLinks: newList});
        this.props.onChange(newList);       
    }

    handleAdd() {
        const newLinkItem = { id: getLinkId(this.state.externalDataLinks), title: '', url: '', dataset: this.props.datasetId};
        this.setState({externalDataLinks: [...this.state.externalDataLinks, newLinkItem]})
        this.props.onChange([...this.state.externalDataLinks, newLinkItem]);
    }

    render() {
        return (
            <React.Fragment>
                {this.state.externalDataLinks &&
                    <React.Fragment>
                        <Row className='form-row'>
                            <Col md={12}>
                            <FontAwesomeIcon className='add-icon float-right icon-color' icon={faPlusSquare} size='lg' onClick={() => this.handleAdd()} />
                            </Col>
                        </Row>
                        {this.state.externalDataLinks.map((link, i) => (
                            <Row className='form-row' key={link.id}>
                                <Col md={3}>
                                    <FormControl>
                                        <TextField variant="outlined" id={'link_title_' + link.id} label="Title" value={link.title || ''} onChange={e => this.handleChange('title', i, link.id, e.target.value, link.url)} helperText="" />
                                    </FormControl>
                                </Col>
                                <Col md={8}>
                                    <FormControl>
                                        <TextField variant="outlined" id={'link_url_' + link.id} label="Url" value={link.url || ''} onChange={e => this.handleChange('url', i, link.id, link.title, e.target.value)} helperText="" />
                                    </FormControl>
                                </Col>
                                <Col md={1}>
                                    <FontAwesomeIcon className='delete-icon float-right icon-color' icon={faTrash} size='lg' onClick={() => this.handleDelete(link.id)} />
                                </Col>
                            </Row>
                        ))}
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

EditExternalLinks.propTypes = {
    // Properties
    externalDataLinks: PropTypes.array,
    currentKey: PropTypes.string.isRequired,
    datasetId: PropTypes.number.isRequired,

    // Functions
    onChange: PropTypes.func
};

export default EditExternalLinks;
