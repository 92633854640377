import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleLeft, faPlusSquare, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import FormHelperText from '@mui/material/FormHelperText';
import NativeSelect from '@mui/material/NativeSelect';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

import ForumEntry from './ForumEntry';
import { getNextId } from './../../common/renders';
import {
    forum_fetch_all_categories,
    forum_fetch_all,
    forum_set_edit_mode,
    forum_set_child_edit_mode,
    forum_create_child,
    forum_delete_child,
    forum_child_changed,
    forum_changed,
    forum_save,
    forum_create,
    forum_delete
} from './../../actions';
import './Forum.scss';

class Forum extends Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedCategoryId: 0,
            number: 10
        }

        this.categoryChanged = this.categoryChanged.bind(this);
        this.addChildClicked = this.addChildClicked.bind(this);
        this.addForumClicked = this.addForumClicked.bind(this);
        this.changeNumber = this.changeNumber.bind(this);
    }

    componentDidMount() {
        this.props.forumFetchAllCategories();
        this.props.forumFetchAll(this.state.selectedCategoryId, this.state.number);
    }

    changeNumber(number) {
        console.log(number);
        this.setState(() => ({ number: number }));
        this.props.forumFetchAll(this.state.selectedCategoryId, number);
    }

    categoryChanged(value) {
        this.setState(() => ({ selectedCategoryId: value }));
        this.props.forumFetchAll(value, this.state.number);
    }

    addForumClicked() {
        const item = {
            id: getNextId(this.props.entries),
            title: '',
            content: '',
            userName: '',
            created: new Date(),
            updated: new Date(),
            categoryId: this.state.selectedCategoryId,
            parentId: null,
            children: [],
            isEditMode: true
        }
        this.props.create(item);
    }

    addChildClicked(id) {
        const child = {
            id: getNextId(this.props.entries),
            title: '',
            content: '',
            userName: '',
            created: new Date(),
            updated: new Date(),
            categoryId: 0,
            parentId: id,
            children: [],
            isEditMode: true
        }

        this.props.createChild(child);
    }

    render() {
        return (
            <Container>
                <Row>
                    <Col md={12}>
                        <div className='title-and-icon'>
                            <Link to="/"><FontAwesomeIcon className='font-awesome-icon' icon={faArrowAltCircleLeft} /></Link>
                            <h1>Forum</h1>
                        </div>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <FormControl>
                            <InputLabel shrink htmlFor="key_select">Category</InputLabel>
                            <NativeSelect
                                value={this.state.selectedCategoryId || ''}
                                onChange={e => this.categoryChanged(e.target.value)}
                                inputProps={{
                                    name: 'Category',
                                    id: 'key_select',
                                }}>

                                <option value="0">All categories</option>
                                {this.props.categories &&
                                    <React.Fragment>
                                        {this.props.categories.map(category => (
                                            <option key={category.id} value={category.id}>{category.name}</option>
                                        ))}
                                    </React.Fragment>
                                }
                            </NativeSelect>
                            <FormHelperText></FormHelperText>
                        </FormControl>
                    </Col>

                    <Col md={9}>
                        {this.state.selectedCategoryId > 0 
                            ? <FontAwesomeIcon className='add-button float-right' icon={faPlusSquare} size='lg' onClick={() => this.addForumClicked()} />
                            : <div>
                                <i>Please select a category before making a new post</i>
                                <FontAwesomeIcon title='Please select a category before making a new post' className='add-button float-right' color='grey' icon={faPlusSquare} size='lg' />
                            </div>
                        }
                    </Col>
                </Row>

                <Row>
                    <Col md={12} className="bigWhiteWrapper">
                        {this.props.entries &&
                            <React.Fragment>
                                {this.props.entries.map(entry => (
                                    <div className='forum-entry' key={entry.id}>
                                        <ForumEntry
                                            entry={entry}
                                            setEditMode={this.props.setEditMode}
                                            setChildEditMode={this.props.setChildEditMode}
                                            addChild={this.addChildClicked}
                                            deleteChild={this.props.deleteChild}
                                            childChanged={this.props.childChanged}
                                            forumChanged={this.props.forumChanged}
                                            forumSave={this.props.forumSave}
                                            forumDelete={this.props.forumDelete}
                                        />
                                    </div>))}
                            </React.Fragment>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FontAwesomeIcon className='float-right icon-color' icon={faAngleRight} size='3x' onClick={() => this.changeNumber(this.state.number + 10)} />
                        <span className='show-more float-right'>Show more</span>
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        entries: state.forum.entries,
        categories: state.forum.forumCategories
    };
}

const mapDispatchToProps = dispatch => {
    return {
        forumFetchAllCategories: () => dispatch(forum_fetch_all_categories()),
        forumFetchAll: (categoryId, number) => dispatch(forum_fetch_all(categoryId, number)),
        setEditMode: (id, value) => dispatch(forum_set_edit_mode(id, value)),
        setChildEditMode: (parentId, id, value) => dispatch(forum_set_child_edit_mode(parentId, id, value)),
        create: (entry) => dispatch(forum_create(entry)),
        createChild: (entry) => dispatch(forum_create_child(entry)),
        deleteChild: (parentId, id) => dispatch(forum_delete_child(parentId, id)),
        childChanged: (parentId, id, message) => dispatch(forum_child_changed(parentId, id, message)),
        forumChanged: (id, title, message, category) => dispatch(forum_changed(id, title, message, category)),
        forumSave: (entry) => dispatch(forum_save(entry)),
        forumDelete: (id) => dispatch(forum_delete(id))
    };
};

Forum.propTypes = {
    // Properties
    entries: PropTypes.array,
    categories: PropTypes.array,

    // Functions
    forumFetchAllCategories: PropTypes.func,
    forumFetchAll: PropTypes.func,
    setEditMode: PropTypes.func,
    setChildEditMode: PropTypes.func,
    createChild: PropTypes.func,
    deleteChild: PropTypes.func,
    childChanged: PropTypes.func,
    forumChanged: PropTypes.func,
    forumSave: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Forum);