import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenSquare, faTrash, faSave, faWindowClose } from '@fortawesome/free-solid-svg-icons';

// Material-ui
import TextField from '@mui/material/TextField';
import './ForumChildEntry.scss';

import { formatDate, formatTime } from './../../common/renders';

class ForumChildEntry extends Component {

    render() {
        return (
            <Container>


                {!this.props.entry.isEditMode &&
                    <div className='entry-child-container'>
                        <Row>
                            <Col>
                                <div className='entry-child-content'>
                                    <p>{this.props.entry.content}</p>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={10}>
                                <span className='float-right'>{formatTime(this.props.entry.updated)}</span>
                                <span className='float-right margin-right'>{formatDate(this.props.entry.updated)}</span>
                                <span className='float-right margin-right'>{this.props.entry.author}</span>
                            </Col>
                            <Col md={2} className='header-menu'>
                                {this.props.entry.deleteButtonVisible &&
                                    <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faTrash} size='lg' onClick={() => this.props.delete(this.props.entry.id)} />
                                }
                                {!this.props.entry.isEditMode && this.props.entry.editButtonVisible &&
                                    <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faPenSquare} size='lg' onClick={() => this.props.setEditMode(this.props.entry.id, !this.props.entry.isEditMode)} />
                                }
                                {this.props.entry.isEditMode &&
                                    <React.Fragment>
                                        <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faWindowClose} size='lg' onClick={() => this.props.setEditMode(this.props.entry.id, !this.props.entry.isEditMode)} />
                                        <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faSave} size='lg' onClick={() => this.props.forumSave(this.props.entry)} />
                                    </React.Fragment>
                                }
                            </Col>
                        </Row>
                    </div>
                }

                {this.props.entry.isEditMode &&
                    <React.Fragment>
                        <div className='entry-child-edit-container'>
                            <Row>
                                <Col>
                                    <TextField
                                        id="message_input"
                                        label="Comment (max 255 characters)"
                                        multiline
                                        minRows={6}
                                        value={this.props.entry.content || ''}
                                        variant="outlined"
                                        inputProps={{maxLength:255}}
                                        onChange={(e) => this.props.childChanged(this.props.entry.id, e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col className='header-menu'>
                                    {!this.props.entry.deleteButtonVisible &&
                                        <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faTrash} size='lg' onClick={() => this.props.delete(this.props.entry.id)} />
                                    }
                                    {!this.props.entry.isEditMode && this.props.entry.editButtonVisible &&
                                        <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faPenSquare} size='lg' onClick={() => this.props.setEditMode(this.props.entry.id, !this.props.entry.isEditMode)} />
                                    }
                                    {this.props.entry.isEditMode &&
                                        <React.Fragment>
                                            <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faWindowClose} size='lg' onClick={() => this.props.setEditMode(this.props.entry.id, !this.props.entry.isEditMode)} />
                                            <FontAwesomeIcon className='header-menu-icon float-right icon-color' icon={faSave} size='lg' onClick={() => this.props.forumSave(this.props.entry)} />
                                        </React.Fragment>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </React.Fragment>
                }

            </Container>

        );
    }
}

ForumChildEntry.propTypes = {
    // Properties
    entry: PropTypes.object,

    // Functions
    setEditMode: PropTypes.func.isRequired,
    delete: PropTypes.func.isRequired,
    childChanged: PropTypes.func.isRequired,
    forumSave: PropTypes.func.isRequired
};

export default ForumChildEntry;
