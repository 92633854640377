import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import logo from 'assets/images/logo.png';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faUsersCog,
    faPenSquare,
    faCommentAlt,
    faQuestionCircle,
    faUserCircle,
} from '@fortawesome/free-solid-svg-icons';

import 'typeface-roboto';
import './Header.scss';

class Header extends Component {
    render() {
        return (
            <Container className='header'>
                {this.props.userData &&
                    this.props.userData.data &&
                    (this.props.userData.data.isAdmin ||
                        this.props.userData.data.isEditor) && (
                        <Row>
                            <Col md={12}>
                                <Link to='/editor'>
                                    <FontAwesomeIcon
                                        className='admin-links float-right icon-color'
                                        icon={faPenSquare}
                                        size='2x'
                                        title='Editor'
                                    />
                                </Link>
                                {this.props.userData.data.isAdmin && (
                                    <React.Fragment>
                                        <Link to='/admin'>
                                            <FontAwesomeIcon
                                                className='admin-links float-right icon-color'
                                                icon={faUsersCog}
                                                size='2x'
                                                title='Admin'
                                            />
                                        </Link>
                                    </React.Fragment>
                                )}
                            </Col>
                        </Row>
                    )}
                <Row className='row-full-width'>
                    <Col md={5}>
                        <div className='logo'>
                            <Link to='/'>
                                <img
                                    className='logo__img'
                                    alt='Equinor Logo'
                                    src={logo}
                                />
                            </Link>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className='navContainer'>
                            <Link
                                className='nav-item text-decoration-none'
                                to='/forum'
                            >
                                <FontAwesomeIcon
                                    className='nav-icon'
                                    icon={faCommentAlt}
                                    size='2x'
                                />
                                <p className='navElements'>Forum</p>
                            </Link>
                        </div>
                    </Col>
                    <Col md={2}>
                        <div className='navContainer'>
                            <Link
                                className='nav-item text-decoration-none'
                                to='/faq'
                            >
                                <FontAwesomeIcon
                                    className='nav-icon'
                                    icon={faQuestionCircle}
                                    size='2x'
                                />
                                <p className='navElements'>FAQ</p>
                            </Link>
                        </div>
                    </Col>
                    <Col md={3}>
                        {this.props.userData?.data && (
                            <React.Fragment>
                                <div className='navContainer'>
                                    <div className='nav-item'>
                                        <FontAwesomeIcon
                                            className='nav-icon'
                                            icon={faUserCircle}
                                            size='2x'
                                        />
                                        <p className='navElements'>
                                            {
                                                this.props.userData?.data
                                                    ?.fullName
                                            }
                                        </p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userData: state.userData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

Header.propTypes = {
    // Properties
    userData: PropTypes.object,

    // Functions
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
