import React, { Component } from 'react';
import ReactQuill from 'react-quill';
import PropTypes from 'prop-types';

import 'react-quill/dist/quill.snow.css';
import './RichTextField.scss';

class RichTextField extends Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);

        this.state = {
            modules: {
                toolbar: [
                    [{ 'header': [2, 3, false] }],
                    ['bold', 'italic'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ['link'],
                    ['clean']
                ]
            },
            formats: [
                'header',
                'bold', 'italic',
                'list', 'bullet',
                'link',
            ]
        }

    }

    handleChange(value) {
        this.props.onChange(value);
    }

    render() {
        return (
            <ReactQuill theme="snow" modules={this.state.modules} formats={this.state.formats} value={this.props.fieldValue} onChange={this.handleChange} />
        );
    }
}

RichTextField.propTypes = {
    // Properties
    fieldValue: PropTypes.string,
    // Functions
    onChange: PropTypes.func,
};

export default RichTextField;
