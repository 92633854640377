import { MsalAuthProvider, LoginType } from "react-aad-msal";

export var loggingType = "regular";

const b2cScopes = [
  "openid",
  "profile",
  "https://equinorpublisheddatab2c.onmicrosoft.com/portal/user_impersonation",
];

const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority:
        "https://equinorpublisheddatab2c.b2clogin.com/equinorpublisheddatab2c.onmicrosoft.com/B2C_1_signup",
    },
    forgotPassword: {
      authority:
        "https://equinorpublisheddatab2c.b2clogin.com/equinorpublisheddatab2c.onmicrosoft.com/B2C_1_reset",
    },
  },
};

const b2cMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_APP_ID_B2C,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" to save cache in cookies to address trusted zones limitations in IE (see: https://github.com/AzureAD/microsoft-authentication-library-for-js/wiki/Known-issues-on-IE-and-Edge-Browser)
  },
};

const regularScopes = [
  "api://" + process.env.REACT_APP_APP_ID + "/user_impersonation",
  "openid",

  "profile",
];

const regularMsalConfig = {
  auth: {
    clientId: process.env.REACT_APP_APP_ID,
    authority:
      "https://login.microsoftonline.com/" + process.env.REACT_APP_TENANT_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};

const authenticationParameters = {
  scopes: regularScopes,
};

// NEW Authentication component
const commonMsalOptions = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + "/",
};

export var authProvider;

const loginMethod = localStorage.getItem("msal.loginMethod");

if (loginMethod) {
  if (loginMethod === "regular") {
    authProvider = new MsalAuthProvider(
      regularMsalConfig,
      authenticationParameters,
      commonMsalOptions
    );
  } else {
    const authenticationParameters = {
      scopes: b2cScopes,
    };
    authProvider = new MsalAuthProvider(
      b2cMsalConfig,
      authenticationParameters,
      commonMsalOptions
    );
  }
}

export async function loginB2C() {
  try {
    const authenticationParameters = {
      scopes: b2cScopes,
    };
    authProvider = new MsalAuthProvider(
      b2cMsalConfig,
      authenticationParameters,
      commonMsalOptions
    );
    localStorage.setItem("msal.loginMethod", "B2C");
    let loginPopup = await showPopUp(b2cScopes);
    return loginPopup;
  } catch (error) {
    if (error.message.substring(0, 11) === "AADB2C90118") {
      await forgotPasswordB2C();
      throw error;
    } else {
      throw new Error("Could not login user");
    }
  }
}

async function forgotPasswordB2C() {
  try {
    const authenticationParameters = {
      scopes: b2cScopes,
    };
    let resetPasswordConfig = {
      auth: {
        clientId: process.env.REACT_APP_APP_ID_B2C,
        authority: b2cPolicies.authorities.forgotPassword.authority,
        validateAuthority: false,
      },
    };
    authProvider = new MsalAuthProvider(
      resetPasswordConfig,
      authenticationParameters,
      commonMsalOptions
    );
    let passResetPopup = await showPopUp(b2cScopes);
    return passResetPopup;
  } catch (error) {
    throw new Error("Not able to reset password");
  }
}

// Login an user
export async function login() {
  try {
    authProvider = new MsalAuthProvider(
      regularMsalConfig,
      authenticationParameters,
      commonMsalOptions
    );
    localStorage.setItem("msal.loginMethod", "regular");
    return await showPopUp(regularScopes);
  } catch (error) {
    throw new Error("Could not login user");
  }
}

// something
async function showPopUp(scopeList) {
  await authProvider.loginPopup({
    scopes: scopeList,
    prompt: "select_account",
  });
}

// Logout an user
export async function logout() {
  try {
    authProvider.logout();
  } catch (error) {
    throw new Error("Could not logout user");
  }
}

// Get the logged in user
export async function getUserAccount() {
  const userAccount = authProvider?.getAccount();
  return userAccount;
}

// Get the user profile
export async function getUserProfile() {
  try {
    var idToken = await authProvider.getIdToken();
    if (idToken) {
      var user = { displayName: idToken.idToken.name };
      return user;
    }
  } catch (error) {
    throw new Error("Could not load user data");
  }
}
