import { actionTypes } from 'common/actionTypes';

const initialState = {
    fetching: false,
    datasets: null,
    dataset: null,
    hasError: false,
    errorMsg: ''
}

export default function dataSetList(state = initialState, action) {
    switch (action.type) {
        // Load the list
        case actionTypes.dataset.FETCH_ALL_SUCCESS_OR_ERROR:
            return { ...state, fetching: false, datasets: action.payload.datasets, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        case actionTypes.dataset.FETCH_ALL:
            return { ...state, fetching: true, hasError: false, errorMsg: '' };

        case actionTypes.dataset.FETCH_DATASET:
            return { ...state, fetching: true, hasError: false, errorMsg: '' };

        case actionTypes.dataset.FETCH_DATASET_SUCCESS_OR_ERROR:
            return { ...state, fetching: false, dataset: action.payload.dataset, hasError: action.payload.hasError, errorMsg: action.payload.errorMsg };

        case actionTypes.common.ACCEPT_DISCLAIMER:
            return { ...state, fetching: true, hasError: false, errorMsg: '' };

        case actionTypes.common.ACCEPT_DISCLAIMER_SUCCESS_OR_ERROR:
            return {
                ...state,
                fetching: false,
                hasError: action.payload.hasError,
                errorMsg: action.payload.errorMsg,
                dataset: {
                    ...state.dataset,
                    userHasAccepted: action.payload.status
                }
            };

        case actionTypes.global.RESET_ERROR:
            return { ...state, hasError: false, errorMsg: '' }

        case actionTypes.dataset.SAVE_DATASET:
            return { ...state, fetching: true, hasError: false, errorMsg: '' }

        case actionTypes.dataset.SAVE_DATASET_SUCCESS_OR_ERROR: {
            if (action.payload.hasError) {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg
                };
            } else {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg,
                    datasets: [
                        ...state.datasets.map((item) => item.id === action.payload.dataset.id ? {
                            ...item,
                            ...action.payload.dataset
                        } : item)
                    ]
                };
            }
        }

        case actionTypes.dataset.CHANGE_PUBLISH_STATUS:
            return { ...state, fetching: true, hasError: false, errorMsg: '' }

        case actionTypes.dataset.CHANGE_PUBLISH_STATUS_SUCCESS_OR_ERROR: {
            if (action.payload.hasError) {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg
                };
            } else {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg,
                    datasets: [
                        ...state.datasets.map((item) => item.id === action.payload.datasetId ? {
                            ...item,
                            published: action.payload.status
                        } : item)
                    ]
                };
            }
        }

        case actionTypes.dataset.DELETE_DATASET:
            return { ...state, fetching: true, hasError: false, errorMsg: '' }

        case actionTypes.dataset.DELETE_DATASET_SUCCESS_OR_ERROR: {
            if (action.payload.hasError) {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg
                };
            } else {
                return {
                    ...state,
                    fetching: false,
                    hasError: action.payload.hasError,
                    errorMsg: action.payload.errorMsg,
                    datasets: [
                        ...state.datasets.filter(item => item.id !== action.payload.datasetId)
                    ]
                };
            }
        }

        default:
            return state;
    }
}