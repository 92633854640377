import { actionTypes } from 'common/actionTypes';

export function dataset_fetch_all(publishStatus) {
    return {
        type: actionTypes.dataset.FETCH_ALL, 
        payload: {
            publishStatus:publishStatus
        }
    };
}

export function dataset_fetch(key) {
    return {
        type: actionTypes.dataset.FETCH_DATASET, 
        payload: {key:key}
    };
}

export function dataset_save(dataset) {
    return {
        type: actionTypes.dataset.SAVE_DATASET, 
        payload: {dataset:dataset}
    };
}

export function change_publish_status(datasetId, status) {
    return {
        type: actionTypes.dataset.CHANGE_PUBLISH_STATUS, 
        payload: {datasetId:datasetId, status:status}
    };
}

export function delete_dataset(datasetId) {
    return {
        type: actionTypes.dataset.DELETE_DATASET, 
        payload: {datasetId:datasetId}
    };
}


