import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { copyToClipboard } from './../../common/renders';
import './DatasetLink.scss';

class DatasetLink extends Component {

    constructor(props) {
        super(props);
        this.copyLink = this.copyLink.bind(this);
    }

    copyLink(e) {
        e.preventDefault();
        copyToClipboard(this.props.dataset.connString);
    }

    render() {
        return (
            <div>
                {this.props.dataset &&
                    <React.Fragment>
                        <h2>Data links</h2>
                        <b>Usage</b>
                        <p>We recommend using <i>Azure Storage Explorer</i> when exploring the content of, or parts of this dataset.
                    Using this tool will enable you to explore and download only the parts you are interested in. <a target="_blank" rel="noopener noreferrer" href="https://azure.microsoft.com/en-us/features/storage-explorer/">Follow instructions here for download and installation</a></p>
                        <p>For connecting to this dataset in Azure Storage Explorer, use the shared access signature URI: <br /> <code>{this.props.dataset.connString}</code>
                            <button className='copy-btn' onClick={(e) => this.copyLink(e)}>
                                <FontAwesomeIcon className='font-awesome-icon' icon={faClipboard} />
                            </button>
                        </p>
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default DatasetLink;
