export const actionTypes = {
    user: {
        LOGIN_USER: 'LOGIN_USER',
        LOGIN_USER_B2C: 'LOGIN_USER_B2C',
        LOGIN_USER_SUCCESS_OR_ERROR: 'LOGIN_USER_SUCCESS_OR_ERROR',
        LOGOUT_USER: 'LOGOUT_USER',
        LOGOUT_USER_SUCCESS_OR_ERROR: 'LOGOUT_USER_SUCCESS_OR_ERROR',
        FETCHING_USER: 'FETCHING_USER',
        FETCHING_USER_SUCCESS_OR_ERROR: 'FETCHING_USER_SUCCESS_OR_ERROR',
        ROLE_TYPE_SUCCESS_OR_ERROR: 'ROLE_TYPE_SUCCESS_OR_ERROR'
    },
    dataset: {
        FETCH_ALL: 'DATASET_FETCH_ALL',
        FETCH_ALL_SUCCESS_OR_ERROR: 'FETCH_ALL_SUCCESS_OR_ERROR',
        FETCH_DATASET: 'FETCH_DATASET',
        FETCH_DATASET_SUCCESS_OR_ERROR: 'FETCH_DATASET_SUCCESS_OR_ERROR',
        SAVE_DATASET: 'SAVE_DATASET',
        SAVE_DATASET_SUCCESS_OR_ERROR: 'SAVE_DATASET_SUCCESS_OR_ERROR',
        CHANGE_PUBLISH_STATUS_SUCCESS_OR_ERROR: 'CHANGE_PUBLISH_STATUS_SUCCESS_OR_ERROR',
        CHANGE_PUBLISH_STATUS: 'CHANGE_PUBLISH_STATUS',
        DELETE_DATASET: 'DELETE_DATASET',
        DELETE_DATASET_SUCCESS_OR_ERROR: 'DELETE_DATASET_SUCCESS_OR_ERROR',
    },
    common: {
        ACCEPT_DISCLAIMER: 'ACCEPT_DISCLAIMER',
        ACCEPT_DISCLAIMER_SUCCESS_OR_ERROR: 'ACCEPT_DISCLAIMER_SUCCESS_OR_ERROR',
        FETCH_FAQ: 'FETCH_FAQ',
        FETCH_FAQ_SUCCESS_OR_ERROR: 'FETCH_FAQ_SUCCESS_OR_ERROR'        
    },
    forum: {
        FORUM_FETCH_ALL_CATEGORIES: 'FORUM_FETCH_ALL_CATEGORIES',
        FORUM_FETCH_ALL_CATEGORIES_SUCCESS_OR_ERROR: 'FORUM_FETCH_ALL_CATEGORIES_SUCCESS_OR_ERROR',
        FORUM_FETCH_ALL: 'FORUM_FETCH_ALL',
        FORUM_FETCH_ALL_SUCCESS_OR_ERROR: 'FORUM_FETCH_ALL_SUCCESS_OR_ERROR',
        FORUM_SET_EDIT_MODE: 'FORUM_SET_EDIT_MODE',
        FORUM_SET_CHILD_EDIT_MODE: 'FORUM_SET_CHILD_EDIT_MODE',
        FORUM_CREATE_CHILD: 'FORUM_CREATE_CHILD',
        FORUM_DELETE_CHILD: 'FORUM_DELETE_CHILD',
        FORUM_DELETE_CHILD_SUCCESS_OR_ERROR: 'FORUM_DELETE_CHILD_SUCCESS_OR_ERROR',
        FORUM_DELETE: 'FORUM_DELETE',
        FORUM_DELETE_SUCCESS_OR_ERROR: 'FORUM_DELETE_SUCCESS_OR_ERROR',
        FORUM_CHILD_CHANGED: 'FORUM_CHILD_CHANGED',
        FORUM_CHANGED: 'FORUM_CHANGED',
        FORUM_SAVE: 'FORUM_SAVE',
        FORUM_SAVE_SUCCESS_OR_ERROR: 'FORUM_SAVE_SUCCESS_OR_ERROR',
        FORUM_CREATE: 'FORUM_CREATE'
    },
    global: {
        RESET_ERROR: 'RESET_ERROR'
    }
}