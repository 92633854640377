import React from 'react';
import './DatasetList.scss';
import DatasetListItem from './DatasetListItem';

class DatasetList extends React.Component {
    render() {
        return (
            <ul className='datasets'>
                 {this.props.datasetList?.map((dataset, i) => {  
                    return(         
                        <DatasetListItem key={dataset.id} dataset={dataset} />
                    );
                    })}
            </ul>
        );
    }
} 

export default DatasetList;

